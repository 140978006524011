import React from 'react';
import { Helmet } from 'react-helmet-async';
import { siteMetadata } from '../../config/site';

type SeoPropsType = {
  title?: string;
  description?: string;
  path?: string;
};

export default (props: SeoPropsType) => {
  const url = `${siteMetadata.siteURL}${props.path ?? ''}`;
  const title = `${siteMetadata.title} ${props.title ?? ''}`;
  const titleAlt = props.title ?? siteMetadata.titleAlt;
  const description = props.description ?? siteMetadata.description;
  // const realPrefix = pathPrefix === '/' ? '' : pathPrefix;
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': url,
      url,
      name: title,
      alternateName: titleAlt,
      headline: title,
      // image: {
      //   '@type': 'ImageObject',
      //   url: siteMetadata.image,
      // },
      description: description,
      // datePublished: buildTime,
      // dateModified: buildTime,
      author: {
        '@type': 'Person',
        name: siteMetadata.author,
      },
      publisher: {
        '@type': 'Organization',
        name: siteMetadata.author,
        // logo: {
        //   '@type': 'ImageObject',
        //   url: siteMetadata.siteURL + realPrefix + logo,
        // },
      },
      isPartOf: siteMetadata.siteURL,
      mainEntityOfPage: {
        '@type': 'WebSite',
        '@id': siteMetadata.siteURL,
      },
    },
  ];

  return (
    <Helmet>
      <html lang={siteMetadata.siteLanguage} />
      <title>{title || siteMetadata.title}</title>
      <meta name="description" content={description || siteMetadata.description} />
      {/* <meta name="image" content={siteMetadata.image} /> */}
      <meta name="apple-mobile-web-app-title" content={siteMetadata.shortName} />
      <meta name="application-name" content={siteMetadata.shortName} />
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      {/* OpenGraph  */}
      <meta property="og:url" content={siteMetadata.url} />
      {/* <meta property="og:type" content={article ? 'article' : null} /> */}
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />
      {/* <meta property="og:image" content={siteMetadata.image} /> */}

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={twitter} /> */}
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      {/* <meta name="twitter:image" content={siteMetadata.image} /> */}
    </Helmet>
  );
};
