export const siteMetadata = {
  email: 'hayakawa.photo.studio@gmail.com',
  siteUrl: `https://syasinya.com`,
  pathPrefix: '/',
  title: 'おかげさまで創業100年 佐渡早川写真', // タイトル
  titleAlt: 'おかげさまで創業100年 佐渡早川写真', // JSONLDのためのタイトル
  description: '佐渡早川写真へようこそ。新潟県佐渡市の写真スタジオなら早川写真で!七五三 婚礼写真 写真だけの結婚式　 お宮参り 百日祝い 入園入学 家族写真など出来上がりの写真にとても定評があり、お子様の瞬間の笑顔をプロのカメラマンがとらえます。',
  url: 'https://syasinya.com', // スラッシュなしのサイトURL
  siteURL: 'https://syasinya.com/', // スラッシュありのサイトURL
  siteLanguage: 'ja', // HTMLの言語（ここでは日本語）
  // logo: 'src/images/logo.png',
  // banner: 'src/images/banner.png',
  favicon: 'src/images/favicon.png', // ファビコン
  shortName: 'Hayakawa', // サイトの略称、12文字以下
  author: 'Hayakawa', // schemaORGJSONLDの作成者
  themeColor: '#3e7bf2',
  backgroundColor: '#d3e0ff',
  twitter: '', // TwitterのID
};
