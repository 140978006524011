import React from 'react';
import styled, { css } from 'styled-components';
import {
  // GatsbyImage, IGatsbyImageData,
  StaticImage,
} from 'gatsby-plugin-image';
import {
  Link,
  // graphql, useStaticQuery
} from 'gatsby';

// type ImageSharpEdge = {
//   childImageSharp: {
//     thumb: IGatsbyImageData;
//     full: IGatsbyImageData;
//   };
// };

type BasePagePropsType = {
  className?: string;
};

const BasePage: React.FC<BasePagePropsType> = ({ className }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     images: allFile(filter: { sourceInstanceName: { eq: "costumes" } }, sort: { fields: name }) {
  //       edges {
  //         node {
  //           childImageSharp {
  //             thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
  //             full: gatsbyImageData(layout: FULL_WIDTH)
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const images = data.images.edges.map((node: ImageSharpEdge) => ({
  //   ...node.childImageSharp,
  //   // Generate name based on the index as caption.
  // }));

  // const COSTUME_LIST = [
  //   {
  //     name: '0~1歳 男の子 女の子',
  //     url: '/costumes/01mw/',
  //     image: '../components/images/costumes/01mw_MG_1304.jpg',
  //   },
  //   {
  //     name: '2~3歳 男の子',
  //     url: '/costumes/23m/',
  //     image: '../components/images/costumes/23m_MG_1373.jpg',
  //   },
  //   {
  //     name: '2~3歳 女の子',
  //     url: '/costumes/23w/',
  //     image: '../components/images/costumes/23w_MG_1490.jpg',
  //   },
  //   {
  //     name: '4~5歳 男の子',
  //     url: '/costumes/45m/',
  //     image: '../components/images/costumes/45m_MG_1423.jpg',
  //   },
  //   {
  //     name: '4~5歳 女の子',
  //     url: '/costumes/45w/',
  //     image: '../components/images/costumes/45w_MG_1567.jpg',
  //   },
  //   {
  //     name: '6~7歳 女の子',
  //     url: '/costumes/67w/',
  //     image: '../components/images/costumes/67w_MG_1553.jpg',
  //   },
  //   {
  //     name: '7~10歳 男の子',
  //     url: '/costumes/710m/',
  //     image: '../components/images/costumes/710m_MG_1360.jpg',
  //   },
  //   {
  //     name: '8~10歳 女の子',
  //     url: '/costumes/810w/',
  //     image: '../components/images/costumes/810w_MG_1597.jpg',
  //   },
  //   {
  //     name: 'ウェディングドレス',
  //     url: '/costumes/dress/',
  //     image: '../components/images/costumes/dress_MG_1689.jpg',
  //   },
  //   {
  //     name: '成人式 振袖',
  //     url: '/costumes/furisode/',
  //     image: '../components/images/costumes/furisode_MG_1668.jpg',
  //   },
  //   {
  //     name: '羽織袴',
  //     url: '/costumes/haorihakama/',
  //     image: '../components/images/costumes/haorihakama_MG_1823.jpg',
  //   },
  //   {
  //     name: 'メンズスーツ',
  //     url: '/costumes/suits/',
  //     image: '../components/images/costumes/suits_MG_1765.jpg',
  //   },
  //   {
  //     name: '打掛',
  //     url: '/costumes/uchikake/',
  //     image: '../components/images/costumes/uchikake_MG_1808.jpg',
  //   },
  // ];

  return (
    <div className={className}>
      <div className="row">
        <div className="col-12">
          <div className="title">
            <h2>
              <span>撮影衣装 💖</span>
            </h2>
          </div>
        </div>

        {/*{COSTUME_LIST.map((item, index) => (
          <div className="col-sm-6" key={`parent${index}`}>
            <div className="home-plan-wrap">
              <Link to={item.url}>
                {images[index].thumb && <GatsbyImage image={images[index].thumb} alt="image" />}
                <p className="costume-title">{item.name}</p>
                <div className="text-center mg-lg d-grid gap-2">
                  <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                    この衣装を見る
                  </button>
                </div>
              </Link>
            </div>
          </div>
        ))}*/}

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/01mw/'}>
              <StaticImage src={'../components/images/costumes/01mw_MG_1304.jpg'} alt="image" />
              <p className="costume-title">0~1歳 男の子女の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/23m/'}>
              <StaticImage src={'../components/images/costumes/23m_MG_1373.jpg'} alt="image" />
              <p className="costume-title">2~3歳 男の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/23w/'}>
              <StaticImage src={'../components/images/costumes/23w_MG_1490.jpg'} alt="image" />
              <p className="costume-title">2~3歳 女の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/45m/'}>
              <StaticImage src={'../components/images/costumes/45m_MG_1423.jpg'} alt="image" />
              <p className="costume-title">4~5歳 男の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/45w/'}>
              <StaticImage src={'../components/images/costumes/45w_MG_1567.jpg'} alt="image" />
              <p className="costume-title">4~5歳 女の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/67w/'}>
              <StaticImage src={'../components/images/costumes/67w_MG_1553.jpg'} alt="image" />
              <p className="costume-title">6~7歳 女の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/710m/'}>
              <StaticImage src={'../components/images/costumes/710m_MG_1360.jpg'} alt="image" />
              <p className="costume-title">7~10歳 男の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/810w/'}>
              <StaticImage src={'../components/images/costumes/810w_MG_1597.jpg'} alt="image" />
              <p className="costume-title">8~10歳 女の子</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/dress/'}>
              <StaticImage src={'../components/images/costumes/dress_MG_1689.jpg'} alt="image" />
              <p className="costume-title wedding">ウェディングドレス</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/suits/'}>
              <StaticImage src={'../components/images/costumes/suits_MG_1765.jpg'} alt="image" />
              <p className="costume-title">メンズスーツ</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/uchikake/'}>
              <StaticImage src={'../components/images/costumes/uchikake_MG_1808.jpg'} alt="image" />
              <p className="costume-title">打 掛</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/haorihakama/'}>
              <StaticImage
                src={'../components/images/costumes/haorihakama_MG_1823.jpg'}
                alt="image"
              />
              <p className="costume-title">羽織袴</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/furisode/'}>
              <StaticImage src={'../components/images/costumes/furisode_MG_1668.jpg'} alt="image" />
              <p className="costume-title">成人式 振 袖</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-6">
          <div className="home-plan-wrap">
            <Link to={'/costumes/houmongi/'}>
              <StaticImage src={'../components/images/costumes/houmongi_MG_1857.jpg'} alt="image" />
              <p className="costume-title">訪問着</p>
              <div className="text-center mg-lg d-grid gap-2">
                <button type="button" className="btn btn-rd btn-clean btn-tickle-me-pink btn-lg">
                  見る
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        .spacer10 {
          margin-bottom: 10px;
        }

        .spacer20 {
          margin-bottom: 20px;
        }

        .spacer30 {
          margin-bottom: 30px;
        }

        .spacer50 {
          margin-bottom: 50px;
        }

        .spacer100 {
          margin-bottom: 100px;
        }
        .home-plan-wrap {
          margin: 0 0 10px;
          padding: 10px;
          border: solid 1px #eee;
          border-radius: 10px;
        }
        .home-plan-title {
          padding: 10px 0;
          margin: 10px 0;
          font-size: 1.4rem;
          font-weight: 200;
          line-height: 2rem;
          text-align: center;
        }
        .costume-title {
          padding: 10px 0;
          margin: 10px 0;
          font-size: 1rem;
          font-weight: 200;
          line-height: 2rem;
          text-align: center;
        }
        .wedding {
          font-size: 0.95rem;
        }
        p.home-bg-list {
          font-size: 1.1rem;
          font-weight: 200;
          line-height: 1.2rem;
        }
        p.home-bg-list span {
          margin: 0 5px 5px 0;
        }

        .btn-rd {
          border-radius: 40px !important;
        }

        .btn-tickle-me-pink {
          background: #ff7fa9 !important;
          color: #ffffff !important;
        }
        .main-title {
          color: red;
          font-weight: 700;
          font-size: 2rem;
          margin: 30px auto;
          text-align: center;
          word-wrap: break-word;
          line-height: 1.5;
        }

        .home-plan-wrap > a {
          color: #000000;
          text-decoration: none;
          font-weight: 600;
        }

        .title {
          h2 {
            position: relative;
            margin: 30px;
            text-align: center;
          }

          h2:before,
          h2:after {
            position: absolute;
            z-index: 0;
            bottom: -10px;
            display: block;
            content: '';
            border: 1em solid rgb(190, 36, 88);
          }

          h2:before {
            left: -30px;
            border-left-width: 15px;
            border-left-color: transparent;
          }

          h2:after {
            right: -30px;
            border-right-width: 15px;
            border-right-color: transparent;
          }

          h2 span {
            position: relative;
            z-index: 1;
            display: block;
            padding: 1rem 2rem;
            color: #fff;
            background: rgb(245, 90, 141);
          }

          h2 span:before,
          h2 span:after {
            position: absolute;
            bottom: -10px;
            display: block;
            width: 10px;
            height: 10px;
            content: '';
            border-style: solid;
            border-color: #b70505 transparent transparent transparent;
          }

          h2 span:before {
            left: 0;
            border-width: 10px 0 0 10px;
          }

          h2 span:after {
            right: 0;
            border-width: 10px 10px 0 0;
          }
        }

        @media screen and (max-width: 480px) {
          .main-title {
            font-size: 1rem;
            margin: 20px auto;
          }
        }
      }
    `;
  }}
`;
export default Page;
